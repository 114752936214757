import React, { useEffect, useMemo } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useItemFromStoreById } from '../../../utils/getItemFromStoreById';
import { TRouteProps } from '../../../utils/routerProvider';
import { $userPlaces, handleUserPlaceCopy } from '../../../store/userPlaces';
import { TextFieldA } from '../../dumb/TextField/A';
import { HeaderA } from '../../smart/Header/A';
import { PhotoSelectA } from '../../smart/PhotoSelectA';
import { apiUserPlaces } from '../../../api/apiUserPlaces';
import { apiImagesGetUrlById } from '../../../api/apiImages';
import { ButtonB } from '../../dumb/Button/B';


export const PageUserPlacesView = React.memo((props: TRouteProps) => {
  const params = props.params;
  const id: number = Number(params.id || 0);

  const [itemOfOrigin, setItemOfOrigin] = useItemFromStoreById($userPlaces, id, () => ({
    id,
    name: '',
    description: '',
    userId: '',
    location: null,
    images: [],
  }));

  const {
    name,
    description,
    userId,
    images,
    location,
  } = itemOfOrigin;

  const photos = useMemo(() => images.map((id: string) => {
    const dataUrl = apiImagesGetUrlById(id);
    return {
      id,
      dataUrl,
      previewUrl: dataUrl,
    };
  }), [images]);


  useEffect(() => {
    id && apiUserPlaces.getItem({
      id,
    }).then((response) => {
      const data = response?.data;
      data && setItemOfOrigin(data);
    });
  }, [id]);
  

  return (
    <div className='sq'>
      <HeaderA id={id}>
        Пользовательское место {name}
      </HeaderA>
      <div className='bs bs b bt1 bcF.1 p15'>
        <TextFieldA
          label="userId"
          value={userId}
          disabled
        />
        <TextFieldA
          className='mt10'
          label="Название"
          value={name}
          disabled
        />
        <TextFieldA
          className='mt10'
          label="Описание"
          value={description}
          disabled
          multiline
          rows={4}
        />
        {
          location ? (
            <>
              <div className='mt20'>
                Локация
              </div>
              <TextFieldA
                className='mt10'
                label="Координаты (широта, долгота)"
                value={[location.lat, location.lon].join(', ')}
                disabled
              />
              <TextFieldA
                className='mt10'
                label="Адрес"
                value={location.address}
                disabled
              />
              <TextFieldA
                className='mt10'
                label="Название локации"
                value={location.locationName}
                disabled
              />
            </>
          ) : null
        }
        <div className='mt20'>
          Изображения
        </div>
        <PhotoSelectA
          className='mt10'
          limit={1}
          value={photos}
          disabled
        />

        <ButtonB
          className='w200 mt15'
          onClick={() => {
            handleUserPlaceCopy(itemOfOrigin);
          }}
        >
          <ContentCopyIcon/>
          <div className='ml10'>Копировать</div>
        </ButtonB>

      </div>
    </div>
  );
});