import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { $celebrationBackgrounds, celebrationBackgroundsAPI } from '../../../store/celebrationBackgrounds';
import { LINK_CELEBRATION_BACKGROUNDS_ADD } from '../../../constants/links';
import { PageListBaseB } from '../../smart/PageListBaseB';
import { CelebrationBackgroundsItem } from './CelebrationBackgroundsItem';
import { apiCelebrationBackgrounds } from '../../../api/apiCelebrationBackgrounds';
import { apiCelebrations } from '../../../api/apiCelebrations';
import { $celebrations, celebrationsAPI } from '../../../store/celebrations';


const handleRemove = async (params: {
  ids: (string | number)[],
}) => {
  await apiCelebrationBackgrounds.remove(params)
  celebrationBackgroundsAPI.remove(params);
};


export const PageCelebrationBackgroundsList = React.memo(() => {
  const [loading, setLoading] = useState(false);
  const celebrations = useUnit($celebrations);
  const items = useUnit($celebrationBackgrounds).map((item) => {
    const {
      celebrationId,
    } = item;

    return {
      ...item,
      celebrationName: celebrations.find((v) => v.id === celebrationId)?.title || ''
    };
  });

  useEffect(() => {
    setLoading(true);
    apiCelebrations.getList().then((response) => {
      celebrationsAPI.set(response?.data || []);
    });

    apiCelebrationBackgrounds.getList().then((response) => {
      celebrationBackgroundsAPI.set(response?.data || []);
      setLoading(false);
    });
  }, [setLoading]);

  return (
    <PageListBaseB
      titleText="Обложки"
      addLink={LINK_CELEBRATION_BACKGROUNDS_ADD}
      addText="Добавить обложку"
      items={items}
      Item={CelebrationBackgroundsItem}
      onRemove={handleRemove}
      loading={loading}
    />
  );
});