import React, { useState } from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import { FormControl, InputLabel, MenuItem } from '@mui/material';


export type TSelectBaseItem = {
  id: string | number,
} & Record<string, any>;

export type TSelectBaseProps = SelectProps<any> & {
  items: TSelectBaseItem[],
  ItemComponent?: any,
};

function DefaultItemComponent(props: {
  data: any
}) {
  const {
    data,
  } = props;
  return data.nameOfReplaced || data.name || data.title || data.id || '';
}


let _LAST_ID = 0;

export const SelectBase = React.memo(({
  items,
  label,
  ItemComponent,
  ...props
}: TSelectBaseProps) => {

  const [id] = useState<string>(() => 'SelectBase__' + (++_LAST_ID));
  ItemComponent = ItemComponent || DefaultItemComponent;


  return (
    <div className={'x12y7s75>.MuiFormLabel-root ' + (props.className || '')}>
      <FormControl fullWidth>
        <InputLabel
          id={id}
        >{label || ''}</InputLabel>
        <Select
          {...props}
          labelId={id}
          className='w'
          variant={props.variant || 'filled'}
        >
          {
            items.map((item) => {
              const {
                id,
              } = item;

              return (
                <MenuItem
                  key={id}
                  value={id}
                >
                  <ItemComponent
                    data={item}
                  />
                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    </div>
  );
});