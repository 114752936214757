import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  TRippleProps,
} from '../Ripple';
import { ButtonIcon } from './Icon';

export const ButtonLeft = React.memo((props: TRippleProps) => {
  return (
    <ButtonIcon
      {...props}
    >
      <KeyboardArrowLeftIcon/>
    </ButtonIcon>
  );
});