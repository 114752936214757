import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { WrapperWithLoading } from '../../dumb/WrapperWithLoading';
import { HeaderA } from '../../smart/Header/A';
import { $placeCollections, placeCollectionsAPI } from '../../../store/collections';
import { useItemFromStoreById } from '../../../utils/getItemFromStoreById';
import { TextFieldA } from '../../dumb/TextField/A';
import { apiPlaceCollections } from '../../../api/apiPlaceCollections';
import { TFile, TPlaceCollection } from '../../../types';
import { apiImagesGetUrlById } from '../../../api/apiImages';
import { trim } from 'lodash';
import { LableA } from '../../dumb/Label/A';
import { PhotoSelectA } from '../../smart/PhotoSelectA';
import { backLocation } from '../../../utils/router';
import { ButtonB } from '../../dumb/Button/B';
import { SelectSmartPlaceIdList } from '../../smart/SelectSmartPlaceIdList';


export const PagePlaceCollectionsEdit = React.memo((props: TRouteProps) => {
  const params = props.params;
  const id: string = params.id || '';

  const [itemOfOrigin, setItemOfOrigin] = useItemFromStoreById($placeCollections, id, () => ({
    id,
    title: '',
    position: 0,
    backgroundImage: '',
    placeIds: [],
  }), (v) => {
    return {
      ...v,
      position: '' + v.position,
    };
  });

  const {
    title,
  } = itemOfOrigin;

  const invalidTitle = useMemo(() => !trim(title), [title]);

  const [backgroundImages, _setBackgroundImages] = useState<TFile[]>([]);
  
  const errorOfBackgroundImages = backgroundImages.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );

  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);


  const handleChangeName = useCallback((e: any) => {
    setItemOfOrigin({
      title: e.target.value,
    });
  }, []);

  const setBackgroundImages = useCallback((items: TFile[]) => {
    _setBackgroundImages(items);
    setItemOfOrigin({
      backgroundImage: items[0]?.id || '',
    });
  }, []);

  const handleChangePosition = useCallback((e: any) => {
    setItemOfOrigin({
      position: (e.target.value) as any,
    });
  }, []);

  const handleChangePlaceIds = useCallback((placeIds: string[]) => {
    setItemOfOrigin({
      placeIds,
    });
  }, []);


  const disabled = invalidTitle
    || errorOfBackgroundImages;


  const handleSubmit = useCallback(() => {
    setItemOfOrigin((data) => {
      if (disabled) {
        setClicked(true);
        return data;
      }
      
      setLoading(true);

      (async () => {
        const id = data.id;

        const params = {
          ...data,
          title: trim(data.title),
          position: parseInt(data.position as any),
        };

        if (id) {
          const {
            error,
          } = await apiPlaceCollections.change({
            id,
            params,
          });

          if (error) {
            throw error;
          }

          placeCollectionsAPI.edit({
            id,
            data,
          });

        } else {
          const {
            error,
            data: _data,
          } = await apiPlaceCollections.create({
            params,
          });

          if (error) {
            throw error;
          }

          if (_data) {
            data = {
              ..._data,
            };
          }
  
          placeCollectionsAPI.add(data);
        }

        backLocation();

      })();

      return data;
    });

  }, [disabled]);

  useEffect(() => {
    id && apiPlaceCollections.getItem({
      id,
    }).then((response) => {
      const data = (response.data || {}) as TPlaceCollection;

      setItemOfOrigin({
        ...data,
      });
      setImage(data.backgroundImage, _setBackgroundImages);

      function setImage(imageId: string, setImageFn: (v: TFile[]) => any) {
        setImageFn((imageId ? [imageId] : []).map((id: string) => {
          const dataUrl = apiImagesGetUrlById(id);
          return {
            id,
            dataUrl,
            previewUrl: dataUrl,
          };
        }));
      }
    });
  }, [id]);
  
    
  return (
    <div className='sq'>
      <HeaderA id={id}>
        {id ? 'Редактирование подборки:' : 'Новая подборка:'} {id}
      </HeaderA>
      <WrapperWithLoading
        className='bs bs b bt1 bcF.1 p15'
        loading={loading}
      >
        <TextFieldA
          className='mt15'
          label="Название"
          value={title}
          onChange={handleChangeName}
          error={invalidTitle && clicked}
        />


        <TextFieldA
          className='mt10'
          label="Position"
          value={itemOfOrigin.position}
          onChange={handleChangePosition}
        />


        <LableA className='mt10'>
          Изображение
        </LableA>
        <PhotoSelectA
          limit={1}
          value={backgroundImages}
          onChange={setBackgroundImages}
        />

        <div className='mt10'>
          Места
        </div>

        <SelectSmartPlaceIdList
          className='mt10'
          value={itemOfOrigin.placeIds}
          onChange={handleChangePlaceIds}
        />

        <ButtonB
          className='w200 mt15'
          onClick={handleSubmit}
          disabled={disabled && clicked}
        >
          Сохранить
        </ButtonB>
      </WrapperWithLoading>
    </div>
  );
});
