import React, { useCallback } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { TFile } from '../../../../types';
import { Ripple } from '../../../dumb/Ripple';
import { ButtonView } from '../../../dumb/Button/View';
import { ButtonDelete } from '../../../dumb/Button/Delete';
import { ButtonReplay } from '../../../dumb/Button/Replay';
import { ButtonReplace } from '../../../dumb/Button/Replace';
import { WrapperWithLoading } from '../../../dumb/WrapperWithLoading';
import { apiImagesPreviewGetUrlById } from '@/api/apiImages';


export type TPhotoSelectItemProps = {
  data: TFile;
  state?: {
    loading?: boolean;
    error?: any;
  };
  onDelete?: (id: string) => void;
  onView?: (id: string) => void;
  onReplace?: (id: string) => void;
  onReUpload?: (id: string) => void;
  disabled?: boolean;
};

const _PhotoSelectItem = React.memo((props: TPhotoSelectItemProps) => {
  const { data, onReplace, onView, onDelete, onReUpload, disabled } = props;
  const state = props.state || {};
  const error = state.error || false;
  const {
    id,
  } = data;

  const handleReplace = useCallback(() => {
    onReplace && onReplace(id);
  }, [id, onReplace]);

  const handleRemove = useCallback(() => {
    onDelete && onDelete(id);
  }, [id, onDelete]);

  const handleRepeat = useCallback(() => {
    onReUpload && onReUpload(id);
  }, [id, onReUpload]);

  const handleView = useCallback(() => {
    onView && onView(id);
  }, [id, onView]);


  return (
    <WrapperWithLoading
      className={disabled ? '' : 'active'}
      loading={state.loading || false}
    >
      <Ripple
        className='sq120 dF aiC jcC ov r8 bs b1 bcF.1'
        onClick={handleView}
      >
        <img className='sq dB ofCover' src={apiImagesPreviewGetUrlById(id)} alt={id}/>
        <div className='abs s bg0.2 dn bg0.0<1:h<2.active'/>
        {error ? (
          <div className='abs s dF aiC jcC bgF00.5'>
            <WarningIcon className='dB*2 sq40*2'/>
          </div>
        ) : null}
        <div className='abs sh sb bg0.8 z1'>
          <div className='pt5 ph5 ov f7 cF'>
            {id}
          </div>
          <div className='dF aiFS'>
            <ButtonView onClick={handleView}/>
            {
              disabled ? null : (
                <>
                  {
                    error ? (
                      <ButtonReplay onClick={handleRepeat}/>
                    ) : (
                      <ButtonReplace onClick={handleReplace}/>
                    )
                  }
                  <ButtonDelete onClick={handleRemove}/>
                </>
              )
            }
          </div>
        </div>
      </Ripple>
    </WrapperWithLoading>
  );
});

export const PhotoSelectItem = React.memo(_PhotoSelectItem);
