import React, { useCallback, useMemo, useRef, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { apiImagesPreviewGetUrlById } from "../../api/apiImages";
import { TextFieldA } from "../dumb/TextField/A";
import { useDebounce } from "../../utils/useDebounce";
import { itemsFilter } from "../../utils/itemsFilter";
import { Loading } from "../dumb/Loading";
import { ButtonDelete } from "../dumb/Button/Delete";
import { Ripple } from "../dumb/Ripple";
import { ButtonLeft } from "../dumb/Button/Left";


type TEntity = {
  id: string;
  name?: string;
  nameOfReplaced?: string;
  selectedImage?: string;
  images?: string[];
};
export type TSelectSmartEntityIdListProps = {
  className?: string;
  value: string[];
  onChange: (value: string[]) => void;
  items: TEntity[];
  loading: boolean
};

const EntitySelected = React.memo((props: {
  data: TEntity;
  onRemove: (id: string) => void;
  onIndexUp: (id: string) => void;
}) => {
  const {
    data,
  } = props;

  const {
    name,
  } = data;
  const images = data.images || [];
  const selectedImage = data.selectedImage || '';
  const previewUrl = apiImagesPreviewGetUrlById(images.find((id) => id === selectedImage) || images[0] || '');


  return (
    <div className="w148 rlv">
      <div className="sq148 r20 ov bgF.1">
        {previewUrl ? (
          <img className="sq dB ofCover" src={previewUrl} alt={name}/>
        ) : null}
      </div>

      <div className="mt6 f15 fw4 lh20 tl cF h40 ov">
        {name}
      </div>
      <div className="abs st5 sr5">
        <ButtonDelete onClick={() => {
          props.onRemove(data.id);
        }}/>
      </div>
      <div className="abs st5 sl5">
        <ButtonLeft onClick={() => {
          props.onIndexUp(data.id);
        }}/>
      </div>
    </div>
  );
});

const EntityUnselected = React.memo((props: {
  data: TEntity;
  onAdd: (id: string) => void;
}) => {
  const {
    data,
  } = props;

  return (
    <Ripple
      className="w dF aiC jcS fxdR h28 ph10 pv5 bgF.1 ov r5 cF f12 lh gap10"
      onClick={() => {
        props.onAdd(data.id);
      }}
    >
      <AddIcon/>
      <div
        className="cF00>b"
        dangerouslySetInnerHTML={{
          __html: (data as any).nameOfReplaced || data.name,
        }}
      />
    </Ripple>
  );
});


export const SelectSmartEntityList = React.memo((props: TSelectSmartEntityIdListProps) => {
  const itemsOfOrigin = props.items;
  const [search, setSearch] = useState('');
  const [searchValueOfDelayed] = useDebounce(search, 1000);
  const {
    value,
  } = props;

  const handleChangeSearch = useCallback((e: any) => {
    setSearch(e.target.value);
  }, []);

  const propsRef = useRef(props);

  const [_unselected, selected] = useMemo(() => {
    const _value = value || [];
    const unselected: TEntity[] = [];
    const selected: (TEntity & {
      index: number;
    })[] = [];

    for (const item of itemsOfOrigin) {
      const index = _value.indexOf(item.id);
      if (index > -1) {
        selected.push({
          ...item,
          index,
        });
      } else {
        unselected.push(item);
      }
    }

    selected.sort((a, b) => a.index - b.index);

    return [unselected, selected];
  }, [value, itemsOfOrigin]);

  const unselected: TEntity[] = (useMemo(() => itemsFilter(_unselected, searchValueOfDelayed, [], ['name']), [_unselected, searchValueOfDelayed]) as any);

  const handleRemove = useCallback((id: string) => {
    const props = propsRef.current;

    props.onChange(props.value.filter((v: string) => v !== id));
  }, []);

  const handleIndexUp = useCallback((id: string) => {
    const props = propsRef.current;
    const value = props.value;
    const index = value.indexOf(id);

    if (index < 1) {
      return;
    }

    const nextIndex = index - 1;
    const output = [...value];
    output[index] = output[nextIndex];
    output[nextIndex] = id;

    props.onChange(output);
  }, []);

  const handleAdd = useCallback((id: string) => {
    const props = propsRef.current;

    props.onChange([...props.value, id]);
  }, []);

  propsRef.current = props;

  return (
    <div className={props.className || ''}>
      <TextFieldA
        className="rlv pr50>input"
        label="Поиск"
        value={search}
        onChange={handleChangeSearch}
        after={props.loading ? (
          <div className="abs sv sr w50">
            <Loading/>
          </div>
        ) : null}
      />

      <div className="hmax200 ovyA bs bcF.2 b0 bv1">
        <div className="dF aiC jcC fxdC gap2 pv2">
          {unselected.map((item) => {
            return (
              <EntityUnselected
                key={item.id}
                data={item}
                onAdd={handleAdd}
              />
            );
          })}
        </div>
      </div>
      <div className="mt10 dF fxdR gap12 fxfWrap p10 bgF.1 r10 hmin200">
        {selected.map((item) => {
          return (
            <EntitySelected
              key={item.id}
              data={item}
              onRemove={handleRemove}
              onIndexUp={handleIndexUp}
            />
          );
        })}
      </div>
    </div>
  );
});
