import {
  createStore,
  createApi,
} from 'effector';


export const cacheProvider = <A extends {
  id: string | number,
}>(defaultValue?: A[], options: {
  getList?: () => Promise<A[]>;
} = {}) => {

  type TState = {
    items: A[];
    inited: boolean;
    loading: boolean;
  };

  const $state = createStore<TState>({
    items: defaultValue || [],
    inited: false,
    loading: false,
  });

  const itemsAPI = createApi($state, {
    setState(prev, payload: Partial<TState>) {
      return {
        ...prev,
        ...payload,
      };
    },
    set: (store, payload: A[]) => {
      return {
        ...store,
        items: payload,
      };
    },
    add: (store, payload: A) => {
      return {
        ...store,
        items: [payload, ...store.items],
      };
    },
    remove: (store, params: {ids: (string | number)[]}) => {
      const {
        ids,
      } = params;
      return {
        ...store,
        items: store.items.filter((item) => !ids.includes(item.id)),
      };
    },
    edit: (store, params: {id: string | number, data: Partial<A>}) => {
      const {
        id,
        data,
      } = params;

      return {
        ...store,
        items: store.items.map((item) => {
          return item.id === id ? {
            ...item,
            ...data,
            id,
          } : item;
        }),
      };
    },
  });

  return {
    $state,
    $items: $state.map((v) => v.items),
    itemsAPI,
    useCache() {
      const state = $state.getState();
      if (state.inited) {
        return state;
      }

      const {
        getList,
      } = options;

      if (!getList) {
        itemsAPI.setState({
          inited: true,
        });
        return $state.getState();
      }

      itemsAPI.setState({
        inited: true,
        loading: true,
      });

      getList().then((items) => {
        itemsAPI.setState({
          loading: false,
          items,
        });
      });

      return $state.getState();
    },
  };
};
