import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUnit } from 'effector-react';
import { $userPlaces, userPlacesAPI } from '../../../../store/userPlaces';
import type { TRouteProps } from '../../../../utils/routerProvider';
import { PageListBaseB } from '../../../smart/PageListBaseB';
import { apiUserPlaces } from '../../../../api/apiUserPlaces';
import { SortHeader } from './SortHeader';
import { UserPlacesItem } from './UserPlacesItem';
import { throttle } from 'lodash';

const ALT_SEARCH_FIELDS = ['id', 'name', 'description', 'userId', 'locationName'];

export const PageUserPlacesList = React.memo((props: TRouteProps) => {
  const [loading, setLoading] = useState(false);
  const items = useUnit($userPlaces);

  const self = useRef({
    finished: false,
    loading: false,
    called: false,
  });

  const getNextItems = useCallback(async () => {
    const {
      current,
    } = self;

    if (current.finished) {
      return;
    }

    current.loading = true;
    setLoading(true);

    const userPlaces = $userPlaces.getState();

    const userPlacesLength = userPlaces.length;

    const response = await apiUserPlaces.getList({
      query: {
        limit: 50,
        offset: userPlacesLength,
      },
    });

    const next = (response?.data || []).map((v) => ({
      ...v,
      locationName: v.location?.locationName || '',
    }));
    
    if (next.length) {

      userPlacesAPI.set([...userPlaces, ...next]);

      if (current.called) {
        current.called = false;
        getNextItems();
        return;
      }

    } else {
      current.finished = true;
    }

    current.loading = false;
    setLoading(false);
  }, []);


  useEffect(() => {
    if (self.current.loading) {
      return;
    }
    userPlacesAPI.set([]);
    getNextItems();
  }, [getNextItems]);


  const handleEnd = useMemo(() => {
    return throttle(() => {
      self.current.called = true;
      self.current.loading || setTimeout(getNextItems);
    }, 500, {
      leading: false,
      trailing: true,
    });
  }, [getNextItems]);


  return (
    <PageListBaseB
      titleText="Пользовательские места"
      items={items}
      Item={UserPlacesItem}
      onEnd={handleEnd}
      loading={loading}
      SortComponent={SortHeader}
      altSearchFields={ALT_SEARCH_FIELDS}
      additionalButtonsCount={1}
    />
  );
});
