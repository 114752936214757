import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  TRippleProps,
} from '../Ripple';
import { ButtonIcon } from './Icon';


export const ButtonCopy = React.memo((props: TRippleProps) => {
  return (
    <ButtonIcon
      {...props}
    >
      <ContentCopyIcon/>
    </ButtonIcon>
  );
});