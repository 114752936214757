import React from 'react';
import { LINK_PLACE_COLLECTIONS_EDIT } from '../../../../constants/links';
import { TPlace } from '../../../../types';
import { ItemA, TItemAPropsBase } from '../../../dumb/Item/A';


export const PlaceCollectionsItem = React.memo((props: TItemAPropsBase<TPlace>) => {
  const {
    data,
  } = props;

  return (
    <ItemA
      {...props}
      link={LINK_PLACE_COLLECTIONS_EDIT}
    >
      <div
        className='w100 ov tov wsNW ph5 cF00>b'
      >
        {data.position}
      </div>
      <div
        className='fx1 wmin0 ov tov wsNW ph5 cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.nameOfReplaced || data.name,
        }}
      />
    </ItemA>
  );
});

