import { createApi, createStore } from 'effector';
import { TUserPlace } from '../types';
import { cacheProvider } from './common';
import { pushLocation } from '../utils/router';
import { LINK_PLACES_ADD } from '../constants/links';

export const {
  $items: $userPlaces,
  itemsAPI: userPlacesAPI,
} = cacheProvider<TUserPlace>([]);

export const $selectedUserPlaceForCopy = createStore<TUserPlace | null>(null);
export const {
  selectUserPlaceForCopy,
} = createApi($selectedUserPlaceForCopy, {
  selectUserPlaceForCopy: (_, data: TUserPlace) => data,
});

export const handleUserPlaceCopy = (data: TUserPlace) => {
  selectUserPlaceForCopy(data);
  pushLocation({
    path: LINK_PLACES_ADD,
  });
};