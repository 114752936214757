import { useEffect, useState } from 'react';
import { createTimeout } from './createTimeout';

export function useDebounce<T>(value: T, delay: number) {
  const hookData = useState(value);
  const setDebouncedValue = hookData[1];

  (useEffect as any)(() => {
    return createTimeout(setDebouncedValue, delay, [value]);
  }, [value, delay]);

  return hookData;
}
