import { escapeRegExp } from "lodash";
import { TItemWithId } from "../types";
import { escapeHTML } from "./escapeHTML";


export const itemsFilter = (
  itemsOfOrigin: TItemWithId[],
  searchValueOfDelayed: string,
  altNameFields?: string[],
  altSearchFields?: string[],
) => {
  const _altNameFields = altNameFields || [];
  const _altSearchFields = altSearchFields || [];
  const searchValueOfTrimed = searchValueOfDelayed.trim().toLowerCase();
  const items = itemsOfOrigin.map((v: TItemWithId) => {

    let altName = v.name || '';

    for (const altNameField of _altNameFields) {
      altName = altName || v[altNameField];
      if (altName) {
        break;
      }
    }

    return {
      ...v,
      name: altName,
    };
  });

  if (!searchValueOfTrimed) {
    return items;
  }

  const regexpName = new RegExp(escapeRegExp(searchValueOfTrimed), 'gim');
  
  const output: TItemWithId[] = [];


  items.forEach((item: TItemWithId) => {
    let index = 0;

    const dist: Record<string, any> = {};

    function iteratee(v: string) {
      index++;
      return `<b>${v}</b>`;
    }

    for (const altSearchField of _altSearchFields) {
      dist[altSearchField + 'OfReplaced'] = escapeHTML('' + (item[altSearchField] || '')).replace(regexpName, iteratee);
    }

    index && output.push({
      ...item,
      ...dist,
    });
  });

  return output;
};