import { $auth } from "../store/auth";
import { param } from "../utils/param";

const HOSTNAME = window.location.hostname;
const DEV = /localhost|backsptest/.test(HOSTNAME);


export const API_URL_HOSTNAME = DEV ? 'https://backsptest.ru' : 'https://backspprod.ru';
// export const API_URL_ENDPOINT = `${API_URL_HOSTNAME}/admin-api`;


export type TApiResponse<A> = {
  data?: A,
  error?: {
    message: string,
  },
};

export const requestBase = (path: string, options: RequestInit) => {
  const token = $auth.getState()?.token;

  if (token) {
    options = {
      ...options,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return fetch(API_URL_HOSTNAME + path, options)
    .then((response) => {
      const {
        status,
      } = response;

      if (status === 401) {
        $auth.setState(null);
        return response.text().then((message) => {
          return {
            error: {
              message,
            },
          };
        });
      }
      return response.json();
    })
    .catch((error) => ({error}));
};
export const request = (path: string, options: RequestInit) => {
  return requestBase('/admin-api' + path, options);
};

export const post = (options: {
  path: string,
  params?: Record<string, any>,
}) => {
  return request(options.path, {
    method: 'POST',
    body: JSON.stringify(options.params || {}),
  });
};
export const put = (options: {
  path: string,
  id?: string | number,
  params?: Record<string, any>,
}) => {
  return request(options.path + (options.id || ''), {
    method: 'PUT',
    body: JSON.stringify(options.params || {}),
  });
};


export const get = (options: {
  path: string,
  id?: string | number,
  query?: Record<string, any>,
}) => {
  const {
    query,
  } = options;
  const qs = query ? param(query) : '';

  return request(options.path + (options.id || '') + (qs ? '?' + qs : ''), {
    method: 'GET',
  });
};
export const remove = (options: {
  path: string,
  ids: (string | number)[],
}) => {
  const {
    ids,
    path,
  } = options;

  return ids.length ? ids.map((id) => {
    return request(path + id, {
      method: 'DELETE',
    });
  }) : Promise.resolve([]);
};


export const apiEntityProvider = <A extends Record<string, any>, Params extends Record<string, any> = A>(entityPrefix: string) => {
  const create = (options: {
    params?: Params,
  }): Promise<TApiResponse<A>> => {
    const {
      params: _params,
    } = options;
    const {
      id,
      ...params
    } = (_params as any);
  
    return post({
      ...options,
      params,
      path: entityPrefix + 'create',
    });
  };
  const change = (options: {
    id: string | number,
    params?: Omit<Params, 'id'>,
  }) => {
    return put({
      ...options,
      path: entityPrefix,
    });
  };
  const getItem = (options: {
    id: string | number,
  }): Promise<TApiResponse<A>> => {
    return get({
      ...options,
      path: entityPrefix,
    });
  };
  const getList = (options?: {
    id?: string | number,
    query?: Record<string, any>,
  }): Promise<TApiResponse<A[]>> => {
    return get({
      ...(options || {}),
      path: entityPrefix,
    });
  };

  return {
    create,
    change,
    getItem,
    getList,
    remove: (options: {
      ids: (string | number)[],
    }) => {
      return remove({
        ...options,
        path: entityPrefix,
      });
    },
  };
};