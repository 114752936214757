import React, { useEffect, useState } from "react";
import { useUnit } from "effector-react";
import { $places, placesAPI } from "../../store/places";
import { apiPlaces } from "../../api/apiPlaces";
import { SelectSmartEntityList } from "./SelectSmartEntityList";


export type TSelectSmartPlaceIdListProps = {
  className?: string;
  value: string[];
  onChange: (value: string[]) => void;
};

export const SelectSmartPlaceIdList = React.memo((props: TSelectSmartPlaceIdListProps) => {
  const itemsOfOrigin = useUnit($places);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    apiPlaces.getList().then((response) => {
      placesAPI.set((response?.data || []).map((v) => {
        return {
          ...v,
          ...(v.location || {
            cityName: '',
            building: '',
            street: '',
            lat: '0' as any,
            lon: '0' as any,
          }),
        };
      }));
      setLoading(false);
    });
  }, []);


  return (
    <SelectSmartEntityList
      {...props}
      loading={loading}
      items={itemsOfOrigin}
    />
  );
});