import React from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseA } from '../../smart/PageListBaseA';
import { PageUserPlacesList } from './UserPlacesList';
import { PageUserPlacesView } from './UserPlacesView';

export const PageUserPlaces = React.memo((props: TRouteProps) => {
  return (
    <PageListBaseA
      {...props}
      PageList={PageUserPlacesList}
      PageEdit={PageUserPlacesView}
    />
  );
});