import { apiCelebrationEvents } from '@/api/apiCelebrationEvents';
import { TCelebrationEventForm } from '../types';
import { cacheProvider } from './common';

export const {
  $items: $celebrationEvents,
  itemsAPI: celebrationEventsAPI,
  useCache: useCelebrationEvents,
} = cacheProvider<TCelebrationEventForm>([], {
  async getList() {
    const response = await apiCelebrationEvents.getList();
    return (response?.data || []).map((v) => {
      return {
        ...v,
        name: v.title,
        ...(v.location || {
          cityName: '',
          building: '',
          street: '',
          lat: '0' as any,
          lon: '0' as any,
        }),
      };
    });
  },
});