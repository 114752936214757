import React from 'react';
import { Checkbox } from '@mui/material';
import { Link } from '../../../utils/router';
import { ButtonCopy } from '../Button/Copy';
import { ButtonDelete } from '../Button/Delete';
import { ButtonEdit } from '../Button/Edit';


export type TItemAPropsBase<A> = {
  data: A & Record<string, any>,
  selected?: boolean,
  onSelect: (id: string | number) => any,
  onRemove?: ((id: string | number) => any) | null,
  onCopy?: ((data: any) => any) | null,
  style?: React.CSSProperties,
  className?: string,
};
export type TItemAProps<A> = TItemAPropsBase<A> & {
  link?: string,
  children?: any,
};

function _ItemA<A extends {id: string | number}>(props: TItemAProps<A>) {
  const {
    link,
    data,
    onRemove,
    onCopy,
  } = props;
  const {
    id,
  } = data;


  return (
    <div
      className={'h50 lh f14 ph10 pv5 bs b1 bc0.0 bcbF.2 dF fxdR aiC jcS '
        + (props.className || '')}
      style={props.style}
    >
      <div
        className='w(|min)200 ov tov wsNW ph5 cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.idOfReplaced || id,
        }}
      />
      {props.children || null}
      {onCopy ? (
        <ButtonCopy
          onClick={() => {
            onCopy(data);
          }}
        />
      ) : null}
      {link ? (
        <Link
          component={ButtonEdit}
          href={link + id}
        />
      ) : null}
      {onRemove ? (
        <>
          <ButtonDelete
            onClick={() => {
              onRemove(id);
            }}
          />
          <Checkbox
            value={props.selected || false}
            onChange={() => {
              props.onSelect(id);
            }}
          />
        </>
      ) : null}
    </div>
  );
};


export const ItemA = React.memo(_ItemA);
