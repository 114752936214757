
const textarea = document.createElement('textarea');

export function escapeHTML(html: string) {
  textarea.textContent = html;
  return textarea.innerHTML;
}
export function unescapeHTML(html: string) {
  textarea.innerHTML = html;
  return textarea.textContent;
}