import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { trim } from 'lodash';
import { TFile, TProductblockState } from '../../types';
import { HeaderA } from './Header/A';
import { CheckboxA } from '../dumb/Checkbox/A';
import { TextFieldA } from '../dumb/TextField/A';
import { ButtonB } from '../dumb/Button/B';
import { WrapperWithLoading } from '../dumb/WrapperWithLoading';
import { TSetStateArg } from '../../utils/getItemFromStoreById';
import { apiImagesGetUrlById } from '../../api/apiImages';
import { PhotoSelectA } from './PhotoSelectA';

export type TProductblockFormProps = {
  className?: string,
  data: TProductblockState,
  onChange: (value: TSetStateArg<TProductblockState>) => any,
  onBack?: () => any,
  onSave: () => any,
};


export const CelebrationProductblockForm = React.memo((props: TProductblockFormProps) => {
  const {
    onChange,
    onSave,
    data,
  } = props;
  const {
    id,
    text,
    link,
    icon,
    celebrationId,
  } = data;


  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [photos, _setPhotos] = useState<TFile[]>([]);

  const invalidText = useMemo(() => !trim(text), [text]);
  const invalidLink = useMemo(() => !trim(link), [link]);

  const invalidIcon = !icon
  const invalidCelebrationId = !celebrationId;

  const errorOfPhotos = photos.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );
  const errorOfPhotosExist = !photos.length;

  const disabled = invalidText 
    || invalidLink
    || invalidIcon
    || invalidCelebrationId
    || errorOfPhotos
    || errorOfPhotosExist;


  const handleChangeText = useCallback((e: any) => {
    onChange({
      text: e.target.value,
    });
  }, []);

  const setPhotos = useCallback((items: TFile[]) => {
    _setPhotos(items);
    onChange({
      icon: items[0]?.id || '',
    });
  }, []);

  const handleChangeLink = useCallback((e: any) => {
    onChange({
      link: e.target.value,
    });
  }, []);

  const handleChangeIsActive = useCallback((e: any) => {
    onChange((v) => {
      return {
        ...v,
        isActive: !v.isActive,
      };
    });
  }, []);

  const handleChangePosition = useCallback((e: any) => {
    onChange({
      position: (e.target.value) as any,
    });
  }, []);

  const handleSubmit = useCallback(() => {
    if (disabled) {
      setClicked(true);
      return;
    }

    setLoading(true);

    onSave();

  }, [disabled, onSave]);

  useEffect(() => {
    if (!icon) {
      return;
    }

    const dataUrl = apiImagesGetUrlById(icon);
    console.log(dataUrl)
    _setPhotos([
      {
        id: icon,
        dataUrl,
        previewUrl: dataUrl,
      },
    ]);
  }, [icon]);

  return (
    <div className={props.className || ''}>
      <HeaderA onBack={props.onBack}>
        {id ? 'Редактирование' : 'Создание'} блока продукта: {text}
      </HeaderA>
      <WrapperWithLoading
        className='bs bs b bt1 bcF.1 p15'
        loading={loading}
      >
        <CheckboxA
          label="Активный"
          checked={data.isActive}
          onChange={handleChangeIsActive}
        />

        <PhotoSelectA
          className='mt10'
          limit={1}
          value={photos}
          onChange={setPhotos}
          error={errorOfPhotosExist && clicked}
        />

        <TextFieldA
          className='mt10'
          label="Название"
          value={text}
          onChange={handleChangeText}
          error={invalidText && clicked}
        />

        <TextFieldA
          className='mt10'
          label="Ссылка"
          value={link}
          onChange={handleChangeLink}
          error={invalidLink && clicked}
        />

        {
          id ? (
            <TextFieldA
              className='mt10'
              label="Position"
              value={data.position}
              onChange={handleChangePosition}
            />
          ) : null
        }

        <ButtonB
          className='w200 mt30'
          onClick={handleSubmit}
          disabled={disabled && clicked}
        >
          Сохранить
        </ButtonB>
      </WrapperWithLoading>
    </div>
  );
});
