import React, { useEffect, useMemo } from 'react';
import { useUnit } from 'effector-react';
import { celebrationEventsAPI, useCelebrationEvents } from '../../../../store/celebrationEvents';
import { LINK_CELEBRATION_EVENTS_ADD } from '../../../../constants/links';
import type { TRouteProps } from '../../../../utils/routerProvider';
import { PageListBaseB } from '../../../smart/PageListBaseB';
import { apiCelebrationEvents } from '../../../../api/apiCelebrationEvents';
import { apiCelebrations } from '../../../../api/apiCelebrations';
import { $celebrations, celebrationsAPI } from '../../../../store/celebrations';
import { CelebrationEventsItem } from './CelebrationEventsItem';
import { SortHeader } from './SortHeader';


const handleRemove = async (params: {
  ids: (string | number)[],
}) => {
  await apiCelebrationEvents.remove(params)
  celebrationEventsAPI.remove(params);
};


export const PageCelebrationEventsList = React.memo((props: TRouteProps) => {
  const { items: itemsOfOrigin, loading } = useCelebrationEvents();

  const celebrations = useUnit($celebrations);

  const items = useMemo(() => {
    return itemsOfOrigin.map((celebrationEvent) => {
      const {
        celebrationId,
      } = celebrationEvent;
  
      return {
        ...celebrationEvent,
        celebrationName: celebrations.find((v) => v.id === celebrationId)?.title || '',
      };
    });
  }, [itemsOfOrigin, celebrations]);

  useEffect(() => {
    apiCelebrations.getList().then((response) => {
      celebrationsAPI.set(response?.data || []);
    });
  }, []);

  return (
    <PageListBaseB
      titleText="Мероприятия"
      addLink={LINK_CELEBRATION_EVENTS_ADD}
      addText="Добавить мероприятие"
      items={items}
      Item={CelebrationEventsItem}
      onRemove={handleRemove}
      loading={loading}
      SortComponent={SortHeader}
    />
  );
});