import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUnit } from 'effector-react';
import { InputAdornment } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SearchIcon from '@mui/icons-material/Search';
import { List, AutoSizer } from 'react-virtualized';
import { paramStore } from '../../utils/stores';
import { Link } from '../../utils/router';
import { useDebounce } from '../../utils/useDebounce';
import { pushCustomModalRemove } from '../../services/customModals/modals/pushCustomModalRemove';
import { ButtonC } from '../dumb/Button/C';
import { Loading } from '../dumb/Loading';
import { TextFieldA } from '../dumb/TextField/A';
import { TItemWithId } from '../../types';
import { itemsFilter } from '../../utils/itemsFilter';


const AutoSizerA = AutoSizer as any;
const ListA = List as any;

const $q = paramStore('q', '');
const handleChangeSearchValue = (e: any) => {
  $q.setState(e.target.value);
};

const $sort = paramStore('s', '');


export type TPageListBaseBProps = {
  items: TItemWithId[],
  onRemove?: ((params: { ids: (string | number)[] }) => any),
  Item: React.ElementType<{
    data: any,
    selected?: boolean,
    onSelect: (id: string | number) => any,
    onRemove?: ((id: string | number) => any) | null,
    style?: React.CSSProperties,
  }>,
  titleText: string,
  addLink?: string,
  addText?: string,
  loading: boolean,
  SortComponent?: any,
  onEnd?: (() => void) | null,
  altNameFields?: string[],
  altSearchFields?: string[],
  additionalButtonsCount?: number;
};

export const PageListBaseB = React.memo((props: TPageListBaseBProps) => {
  const {
    onRemove,
    Item,
    items: itemsOfOrigin,
    SortComponent,
    addLink,
    addText,
    onEnd,
    altNameFields: _altNameFields,
    altSearchFields: _altSearchFields,
    loading,
    additionalButtonsCount,
  } = props;

  const altNameFields = useMemo(() => _altNameFields || ['name', 'title', 'text'], [_altNameFields]);
  const altSearchFields = useMemo(() => _altSearchFields || ['id', 'name'], [_altSearchFields]);

  const [selectedItems, setSelectedItems] = useState<(string | number)[]>([]);

  const handleToggleSelected = useCallback((id: string | number) => {
    setSelectedItems((items: (string | number)[]) => {
      items = [...items];
      const index = items.indexOf(id);
      index > -1 ? items.splice(index, 1) : items.push(id);
      return items;
    });
  }, [setSelectedItems]);

  const handleRemove = useCallback(() => {
    pushCustomModalRemove({
      onAccept: () => {
        setSelectedItems((selectedItems: (string | number)[]) => {
          onRemove?.({
            ids: selectedItems,
          });
          return [];
        });
      },
    });
  }, [setSelectedItems, onRemove]);
  
  const handleRemoveById = useCallback((id: string | number) => {
    pushCustomModalRemove({
      onAccept: () => {
        setSelectedItems((selectedItems: (string | number)[]) => {
          const index = selectedItems.indexOf(id);
          if (index > -1) {
            selectedItems = [...selectedItems];
            selectedItems.splice(index, 1);
          }

          onRemove?.({
            ids: [id],
          });
          return selectedItems;
        });
      },
    });
  }, [setSelectedItems, onRemove]);

  
  const searchValue = useUnit($q);
  const [searchValueOfDelayed] = useDebounce(searchValue, 1000);

  const sortValue = useUnit($sort);

  const itemsOfSearch: TItemWithId[] = useMemo(() => {
    return itemsFilter(itemsOfOrigin, searchValueOfDelayed, altNameFields, altSearchFields);
  }, [itemsOfOrigin, searchValueOfDelayed, altNameFields, altSearchFields]);

  const items: TItemWithId[] = useMemo(() => {
    if (!sortValue) {
      return itemsOfSearch;
    }

    const [firstItem] = itemsOfSearch;

    if (!firstItem) {
      return itemsOfSearch;
    }

    const [p0, p1] = sortValue.split('!');
  
    const currentField = p0 || p1;
    const sign = p1 && !p0 ? -1 : 1;

    const items = [...itemsOfSearch];

    const fieldValue = firstItem[currentField];
    const fieldType = typeof fieldValue;
    let sortIteratee = (a: any, b: any) => a[currentField] - b[currentField];
    if (fieldType === 'boolean') {
      sortIteratee = (a: any, b: any) => {
        const av = a[currentField];
        return av === b[currentField] ? 0 : (av ? 1 : -1)
      };
    }
    if (fieldType === 'string') {
      sortIteratee = (a: any, b: any) => a[currentField].localeCompare(b[currentField]);
    }


    items.sort((a, b) => sign * sortIteratee(a, b));
    

    return items;
  }, [itemsOfSearch, sortValue]);

  const itemsOfOriginLength = itemsOfOrigin.length;
  const itemsLength = items.length;
  const endIndex = itemsLength - 1;

  useEffect(() => {
    itemsOfOriginLength && !loading && itemsLength < 1 && onEnd && onEnd();
  }, [onEnd, itemsLength, loading, itemsOfOriginLength]);

  return (
    <div className='sq rlv wmin500'>
      <div className='h50 dF fxdR aiC jcSB pr10'>
        <div className='dF fxdR aiC jcS'>
          <div className='sq50 dF fxdR aiC jcC rlv'>
          {
            loading ? (
              <Loading className='sq26-i>.Loading__Inner'/>
            ) : (
              <ListAltIcon/>
            )
          }
          </div>
          <div className='f18 pr25 wmin220'>
            <span>{props.titleText}</span> <span className='tc dIB bgF.1 r p5 f12 ml5 rlv st-2 w30'>{itemsLength}</span>
          </div>
          <TextFieldA
            className='mt10>.MuiInputBase-root'
            label="Поиск"
            value={searchValue}
            onChange={handleChangeSearchValue}
            variant="standard"
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className='dF fxdR aiC jcS ph5>1'>
          {
            addLink ? (
              <div>
                <Link
                  component={ButtonC}
                  href={addLink}
                >
                  {addText || addLink}
                </Link>
              </div>
            ) : null
          }
          {
            onRemove ? (
              <div>
                <ButtonC
                  disabled={selectedItems.length < 1}
                  onClick={handleRemove}
                >
                  Удалить
                </ButtonC>
              </div>
            ) : null
          }
        </div>
      </div>
      {
        SortComponent ? (
          <div className='h50 dF fxdR aiC jcFS pr10 pl215 bs b bt1 bcF.1'>
            <div className='dF fxdR aiC jcFS wmin0 fx1'>
              <SortComponent
                onChange={$sort.setState}
                value={sortValue}
              />
            </div>
            {
              additionalButtonsCount ? (<div
                style={{
                  minWidth: 40 * additionalButtonsCount,
                  width: 40 * additionalButtonsCount,
                }}
              />) : null
            }
            {
              onRemove ? (
                <div className='w(|min)80'/>
              ) : null
            }
            <div className='w(|min)42'/>
          </div>
        ) : null
      }
      <div
        className={'abs sh st50 sb bs b bt1 bcF.1 ov st100.hasSort'
          + (SortComponent ? ' hasSort' : '')
        }
      >
        <AutoSizerA
          className="sq-i"
          children={(props: any) => {
            return (
              <ListA
                height={props.height}
                width={props.width}
                rowCount={itemsLength}
                rowHeight={50}
                overscanRowCount={1}
                rowRenderer={(props: any) => {
                  const {
                    index,
                  } = props;
                  const item = items[index];
                  const {
                    id,
                  } = item;

                  if (index >= endIndex && onEnd) {
                    onEnd();
                  }

                  return (
                    <Item
                      key={props.key || id}
                      data={item}
                      selected={selectedItems.includes(id)}
                      onSelect={handleToggleSelected}
                      onRemove={onRemove ? handleRemoveById : null}
                      style={props.style}
                    />
                  );
                }}
                noRowsRenderer={() => (
                  <div className="dF fxdR aiC jcC w h100">
                    Список пуст
                  </div>
                )}
              />
            );
          }}
        />
        {loading ? (
          <div className='h70 dF fxdR aiC jcFS abs sb sh bg0.5 ftbBlur4'>
            <Loading/>
          </div>
        ) : null}
      </div>
    </div>
  );
});