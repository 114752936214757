import React from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseA } from '../../smart/PageListBaseA';
import { PagePlaceCollectionsList } from './PlaceCollectionsList';
import { PagePlaceCollectionsEdit } from './PlaceCollectionsEdit';


export const PagePlaceCollections = React.memo((props: TRouteProps) => {
  return (
    <PageListBaseA
      {...props}
      PageList={PagePlaceCollectionsList}
      PageAdd={PagePlaceCollectionsEdit}
      PageEdit={PagePlaceCollectionsEdit}
    />
  );
});