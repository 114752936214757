import React, { useEffect, useMemo } from 'react';
import { useUnit } from 'effector-react';
import { placesAPI, usePlaces } from '../../../../store/places';
import { LINK_PLACES_ADD } from '../../../../constants/links';
import type { TRouteProps } from '../../../../utils/routerProvider';
import { PageListBaseB } from '../../../smart/PageListBaseB';
import { apiPlaces } from '../../../../api/apiPlaces';
import { apiRegions } from '../../../../api/apiRegions';
import { $regions, regionsAPI } from '../../../../store/regions';
import { SortHeader } from './SortHeader';
import { PlacesItem } from './PlacesItem';


const handleRemove = async (params: {
  ids: (string | number)[],
}) => {
  await apiPlaces.remove(params)
  placesAPI.remove(params);
};


export const PagePlacesList = React.memo((props: TRouteProps) => {
  const { items: itemsOfOrigin, loading } = usePlaces();
  const regions = useUnit($regions);

  const items = useMemo(() => {
    return itemsOfOrigin.map((place) => {
      const {
        regionId,
      } = place;
  
      return {
        ...place,
        regionName: regions.find((v) => v.id === regionId)?.name || ''
      };
    });
  }, [itemsOfOrigin, regions]);

  useEffect(() => {
    apiRegions.getList().then((response) => {
      regionsAPI.set(response?.data || []);
    });
  }, []);


  return (
    <PageListBaseB
      titleText="Места"
      addLink={LINK_PLACES_ADD}
      addText="Добавить место"
      items={items}
      Item={PlacesItem}
      onRemove={handleRemove}
      loading={loading}
      SortComponent={SortHeader}
    />
  );
});
