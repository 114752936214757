import React from "react";
import { useCelebrationEvents } from "../../store/celebrationEvents";
import { SelectSmartEntityList } from "./SelectSmartEntityList";


export type TSelectSmartCelebrationEventIdListProps = {
  className?: string;
  value: string[];
  onChange: (value: string[]) => void;
};

export const SelectSmartCelebrationEventIdList = React.memo((props: TSelectSmartCelebrationEventIdListProps) => {
  const { items, loading } = useCelebrationEvents();

  return (
    <SelectSmartEntityList
      {...props}
      loading={loading}
      items={items}
    />
  );
});