import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { $placeCollections, placeCollectionsAPI } from '../../../../store/collections';
import { LINK_PLACE_COLLECTIONS_ADD } from '../../../../constants/links';
import type { TRouteProps } from '../../../../utils/routerProvider';
import { PageListBaseB } from '../../../smart/PageListBaseB';
import { apiPlaceCollections } from '../../../../api/apiPlaceCollections';
import { SortHeader } from './SortHeader';
import { PlaceCollectionsItem } from './PlaceCollectionsItem';


const handleRemove = async (params: {
  ids: (string | number)[],
}) => {
  await apiPlaceCollections.remove(params)
  placeCollectionsAPI.remove(params);
};


export const PagePlaceCollectionsList = React.memo((props: TRouteProps) => {
  const [loading, setLoading] = useState(false);

  const items = useUnit($placeCollections);


  useEffect(() => {
    setLoading(true);

    apiPlaceCollections.getList().then((response) => {
      placeCollectionsAPI.set((response?.data || []).sort((a, b) => a.position - b.position));
      setLoading(false);
    });
  }, [setLoading]);


  return (
    <PageListBaseB
      titleText="Места"
      addLink={LINK_PLACE_COLLECTIONS_ADD}
      addText="Добавить подборку"
      items={items}
      Item={PlaceCollectionsItem}
      onRemove={handleRemove}
      loading={loading}
      SortComponent={SortHeader}
    />
  );
});
