import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { LINK_STORIES_ADD } from '../../../../constants/links';
import { $stories, storiesAPI } from '../../../../store/stories';
import { $regions, regionsAPI } from '../../../../store/regions';
import { apiStories } from '../../../../api/apiStories';
import { apiRegions } from '../../../../api/apiRegions';
import type { TRouteProps } from '../../../../utils/routerProvider';
import { PageListBaseB } from '../../../smart/PageListBaseB';
import { StoriesItem } from './StoriesItem';
import { SortHeader } from './SortHeader';


const handleRemove = async (params: {
  ids: (string | number)[],
}) => {
  await apiStories.remove(params)
  storiesAPI.remove(params);
};

export const PageStoriesList = React.memo((props: TRouteProps) => {
  const [loading, setLoading] = useState(false);
  const regions = useUnit($regions);
  const items = useUnit($stories).map((story) => {
    const {
      regionId,
    } = story;

    return {
      ...story,
      regionName: regions.find((v) => v.id === regionId)?.name || ''
    };
  });

  useEffect(() => {
    setLoading(true);
    apiRegions.getList().then((response) => {
      regionsAPI.set(response?.data || []);
    });

    apiStories.getList().then((response) => {
      storiesAPI.set(response?.data || []);
      setLoading(false);
    });
  }, [setLoading]);

  return (
    <PageListBaseB
      titleText="Сторис"
      addLink={LINK_STORIES_ADD}
      addText="Добавить сторис"
      items={items}
      Item={StoriesItem}
      onRemove={handleRemove}
      loading={loading}
      SortComponent={SortHeader}
    />
  );
});