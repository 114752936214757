import { apiPlaces } from '@/api/apiPlaces';
import { TPlaceForm } from '../types';
import { cacheProvider } from './common';

export const {
  $items: $places,
  itemsAPI: placesAPI,
  useCache: usePlaces,
} = cacheProvider<TPlaceForm>([], {
  async getList() {
    const response = await apiPlaces.getList();
    return (response?.data || []).map((v) => {
      return {
        ...v,
        ...(v.location || {
          cityName: '',
          building: '',
          street: '',
          lat: '0' as any,
          lon: '0' as any,
        }),
      };
    });
  },
});

