import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export type TTextFieldAProps = TextFieldProps & {
  after?: any;
};

export const TextFieldA = React.memo(({
  after,
  className,

  ...props
}: TTextFieldAProps) => {
  return (
    <div className={className || ''}>
      <TextField
        {...props}
        className='w'
        variant={props.variant || 'filled'}
        helperText={props.helperText || (props.error ? 'Поле обязательно' : '')}
      />
      {after}
    </div>
  );
});