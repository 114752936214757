import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { LINK_MAIN_BANNERS_ADD } from '../../../constants/links';
import { $mainBanners, mainBannersAPI } from '../../../store/mainBanners';
import { apiMainBanners } from '../../../api/apiMainBanners';
import type { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseB } from '../../smart/PageListBaseB';
import { MainBannersItem } from './MainBannersItem';



const handleRemove = async (params: {
  ids: (string | number)[],
}) => {
  await apiMainBanners.remove(params)
  mainBannersAPI.remove(params);
};

export const PageMainBannersList = React.memo((props: TRouteProps) => {
  const [loading, setLoading] = useState(false);
  const items = useUnit($mainBanners);

  useEffect(() => {
    setLoading(true);
    apiMainBanners.getList().then((response) => {
      mainBannersAPI.set(response?.data || []);
      setLoading(false);
    });
  }, [setLoading]);

  return (
    <PageListBaseB
      titleText="Основные баннеры"
      addLink={LINK_MAIN_BANNERS_ADD}
      addText="Добавить баннер"
      items={items}
      Item={MainBannersItem}
      onRemove={handleRemove}
      loading={loading}
    />
  );
});