import React from 'react';
import { TFile } from '../../../types';
import { apiImagesSerialUpload } from '../../../api/apiImages';
import {
  openLink,
} from '../../../utils/openLink';
import {
  TWithFileToolsProps,
  withFileTools,
} from '../../../utils/withFileTools';
import { PhotoSelectItem } from './PhotoSelectItem';
import { PhotoSelectAdd } from './PhotoSelectAdd';


export type TPhotoSelectProps = {
  className?: string;
  value?: TFile[];
  onChange?: (value: TFile[]) => void;
  limit?: number;
  error?: boolean;
  disabled?: boolean;
};

async function showImages(params: {
  images: string[],
  start_index: number,
}) {
  return openLink(params.images[params.start_index || 0]);
}

const FILES_LIMIT = 5;
const FILE_ACCEPT = 'image/*';

class _PhotoSelectA extends React.Component {
  constructor(props: TPhotoSelectProps & TWithFileToolsProps) {
    super(props);

    const self = this; // eslint-disable-line
    const selectFiles = props.selectFiles;

    const handleView = (id: string) => {
      const value: TFile[] = (self.props as TPhotoSelectProps).value || [];
      showImages({
        images: value.map((v) => v.dataUrl),
        start_index: value.findIndex((v) => v.id === id),
      });
    };

    function photoReplace(id: string, photo: TFile) {
      const props = self.props as TPhotoSelectProps;
      const { onChange } = props;
      if (!onChange) {
        return;
      }
      const value = props.value || [];

      onChange(value.map((file) => (file.id === id ? {
        ...photo,
        loading: false,
      } : file)));
    }

    function handleAddFiles() {
      const props = self.props as TPhotoSelectProps & TWithFileToolsProps;
      const _limit = props.limit;
      const limit = typeof _limit == 'number' ? _limit : FILES_LIMIT;

      selectFiles({
        limit: limit - (props.value?.length || 0),
        accept: FILE_ACCEPT,
      }).then((newPhotos: TFile[]) => {
        const props = self.props as TPhotoSelectProps;
        const { onChange } = props;
        if (!onChange || !newPhotos.length) {
          return;
        }

        onChange([
          ...(props.value || []),
          ...newPhotos.map((v) => ({
            ...v,
            loading: true,
          })),
        ]);

        apiImagesSerialUpload(newPhotos, photoReplace);
      });
    }

    function handleReUpload(id: string, photo?: TFile) {
      const props = self.props as TPhotoSelectProps;
      const { onChange } = props;
      if (!onChange) {
        return;
      }

      const photos = props?.value || [];
      const _photo = {
        ...(photo || photos.find((v) => v.id === id)),
        id,
      } as TFile;
      const next = photos.map((file) => {
        return file.id === id
          ? {
              ..._photo,
              id,
              loading: true,
              error: null,
            }
          : file;
      });

      onChange(next);
      apiImagesSerialUpload([_photo], photoReplace);
    }

    function handleReplace(id: string) {
      selectFiles({
        limit: 1,
        accept: FILE_ACCEPT,
      }).then((newPhotos) => {
        newPhotos.length && handleReUpload(id, newPhotos[0]);
      });
    }

    function handleDelete(id: string) {
      const props = self.props as TPhotoSelectProps;
      const { onChange } = props;
      if (!onChange) {
        return;
      }
      const photos: TFile[] = (props?.value || []).filter((file: TFile) => file.id !== id);
      onChange(photos);
    }

    self.render = () => {
      const props = self.props as TPhotoSelectProps;
      const value: TFile[] = props.value || [];
      const disabled = props.disabled || false;
      const error = props.error;
      const _limit = props.limit;
      const limit = typeof _limit == 'number' ? _limit : FILES_LIMIT;

      return (
        <div className={'PhotoSelectA w ' + (props.className || '')}>
          <div className='PhotoSelectA__Inner mh-4 cfx (lt|p4)>1'>
            {value.length >= limit || disabled ? null : (
              <div>
                <PhotoSelectAdd
                  className={error ? 'PhotoSelectA__AddError' : ''}
                  onClick={handleAddFiles}
                />
              </div>
            )}
            {value.map((photo: TFile) => (
              <div key={photo.id}>
                <PhotoSelectItem
                  data={photo}
                  onDelete={handleDelete}
                  onReplace={handleReplace}
                  onReUpload={handleReUpload}
                  onView={handleView}
                  disabled={disabled}
                />
              </div>
            ))}
          </div>
        </div>
      );
    };
  }
}

export const PhotoSelectA: React.FC<TPhotoSelectProps> = withFileTools(_PhotoSelectA as any);
