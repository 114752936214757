import React from 'react';
import { LINK_USER_PLACES_EDIT } from '../../../../constants/links';
import { TUserPlace } from '../../../../types';
import { ItemA, TItemAPropsBase } from '../../../dumb/Item/A';
import { handleUserPlaceCopy } from '../../../../store/userPlaces';
import { escapeHTML } from '../../../../utils/escapeHTML';


export const UserPlacesItem = React.memo((props: TItemAPropsBase<TUserPlace>) => {
  const {
    data,
  } = props;

  return (
    <ItemA
      {...props}
      link={LINK_USER_PLACES_EDIT}
      onCopy={handleUserPlaceCopy}
    >
      <div
        className='w300 ov tov wsNW ph5 cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.nameOfReplaced || escapeHTML(data.name),
        }}
      />
      <div
        className='fx1 wmin0 ov tov wsNW ph5 cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.descriptionOfReplaced || escapeHTML(data.description),
        }}
      />
      <div
        className='w(|min)200 ov tov wsNW ph5 cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.locationNameOfReplaced || escapeHTML(data.locationName || ''),
        }}
      />
    </ItemA>
  );
});

