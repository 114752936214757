import * as links from '../constants/links';
import {
  routerByLocationProvider,
} from '../utils/router';
import { TRouteArgs, TRouteProps } from '../utils/routerProvider';
import { PageLogin } from '../components/pages/Login';
import { PagePlaces } from '../components/pages/Places';
import { PageRegions } from '../components/pages/Regions';
import { PageMainBanners } from '../components/pages/MainBanners';
import { PageInterestingBanners } from '../components/pages/InterestingBanners';
import { PageStories } from '../components/pages/Stories';
import { PageCelebrations } from '../components/pages/Celebrations';
import { PageCelebrationEvents } from '../components/pages/CelebrationEvents';
import { PageCelebrationEmojis } from '../components/pages/CelebrationEmojis';
import { PageCelebrationBackgrounds } from '../components/pages/CelebrationBackgrounds';
import { PageUserPlaces } from '../components/pages/UserPlaces';
import { PagePlaceCollections } from '../components/pages/PlaceCollections';


export const $route = routerByLocationProvider([
  [`^${links.LINK_REGIONS}(/((add|edit):childPage(/([^/]+):id)?))?$`, {
    main: PageRegions,
  }],
  [`^${links.LINK_PLACES}(/((add|edit):childPage(/([^/]+):id)?))?$`, {
    main: PagePlaces,
  }],
  [`^${links.LINK_MAIN_BANNERS}(/((add|edit):childPage(/([^/]+):id)?))?$`, {
    main: PageMainBanners,
  }],
  [`^${links.LINK_INTERESTING_BANNERS}(/((add|edit):childPage(/([^/]+):id)?))?$`, {
    main: PageInterestingBanners,
  }],
  [`^${links.LINK_STORIES}(/((add|edit):childPage(/([^/]+):id)?))?$`, {
    main: PageStories,
  }],
  [`^${links.LINK_CELEBRATIONS}(/((add|edit):childPage(/([^/]+):id)?))?$`, {
    main: PageCelebrations,
  }],
  [`^${links.LINK_CELEBRATION_EVENTS}(/((add|edit):childPage(/([^/]+):id)?))?$`, {
    main: PageCelebrationEvents,
  }],
  [`^${links.LINK_CELEBRATION_EMOJIS}(/((add|edit):childPage(/([^/]+):id)?))?$`, {
    main: PageCelebrationEmojis,
  }],
  [`^${links.LINK_CELEBRATION_BACKGROUNDS}(/((add|edit):childPage(/([^/]+):id)?))?$`, {
    main: PageCelebrationBackgrounds,
  }],
  [`^${links.LINK_USER_PLACES}(/((add|edit):childPage(/([^/]+):id)?))?$`, {
    main: PageUserPlaces,
  }],
  [`^${links.LINK_PLACE_COLLECTIONS}(/((add|edit):childPage(/([^/]+):id)?))?$`, {
    main: PagePlaceCollections,
  }],
  [`^${links.LINK_LOGIN}$`, {
    main: PageLogin,
    public: true,
  }],
] as TRouteArgs<{
  main?: React.ElementType<TRouteProps>,
  public?: boolean,
}>[], (params, location) => ({
  // default
  pattern: '',
  main: PageRegions,
}));